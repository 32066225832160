import { fastUrlUtils } from '@nbit/utils'

const { getFastUrl } = fastUrlUtils
const ossDomain = getFastUrl('https://markcoin-oss.oss-ap-southeast-1.aliyuncs.com')

/** web OSS 渐变色 svg 地址 */
export const oss_svg_image_domain_address = `${ossDomain}/website/vpn/image/`

/** OSS icon 文件地址 */
export const oss_svg_domain_address = `${ossDomain}/website/wanliyun/icon/icon_wanliyun_website.js`

/** 国家国旗图片 png 地址 */
export const oss_area_code_image_domain_address = `${ossDomain}/common/area_code_img/`

/** web OSS 万里云 地址 */
export const oss_svg_wanliyun_domain_address = `${ossDomain}/website/wanliyun/`
